import Vue from 'vue'
// import ElementUI from 'element-ui' // ElementUI
// import 'element-ui/lib/theme-chalk/index.css' // ElementUI
import App from './App.vue'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta';
// import VueCompositionApi from '@vue/composition-api' //如果使用vue 2.6以下的版本需要配置：

Vue.config.productionTip = false // 取消生产提示
Vue.config.devtools = true


// Vue.use(VueCompositionApi); //如果使用vue 2.6以下的版本需要配置：
// Vue.use(ElementUI) // 添加

Vue.use(VueMeta);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
